import React, { useEffect } from "react"
import ClassroomLayout from "../components/classroom/layout/classroom-layout"
import { graphql } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import CourseList from "../components/classroom/course/course-list"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../redux/app-actions"

export default function Classroom(props) {
  const dispatch = useDispatch();
  
  const courses = props.data.scocms.listCourses.data

  useEffect(()=>{
    dispatch(setContextSidebar({}));
  }, [])

  return (
    <ClassroomLayout>
      <section className="site-header">
        <Container>
          <Row>
            <Col xs="auto" className="d-flex align-items-center">
              <FontAwesomeIcon fontSize={"50px"} icon={faGraduationCap} />
            </Col>
            <Col>
              <h1>Courses</h1>
              <p>
                On SUPAssist you will learn the Basics of Paddle Boarding
                through our “Introduction to SUP” course, carefully put together
                by a team of experienced SUP Instructors and Industry Leaders
                from the thrilling world of Water Sports.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <div>
          <CourseList courses={courses} />
        </div>
      </Container>
    </ClassroomLayout>
  )
}

export const query = graphql`
  query GetCourses {
    scocms {
      listCourses {
        data {
          id
          slug
          name
          description
          hours
        }
      }
    }
  }
`
