import { faArrowCircleRight, faClock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigate } from "gatsby"
import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"

export default function CourseList({ courses }) {
  const lstCourses = []
  for (const [i, c] of courses.entries()) {
    lstCourses.push(
      <Card key={i}>
        <Card.Body>
          <Card.Title>{c.name}</Card.Title>
          <Card.Text>
            <Row>
              <Col>
                {c.description}

                <div className="mt-2">
                  <FontAwesomeIcon className="me-2" icon={faClock} />
                  {c.hours} hours
                </div>
              </Col>
              <Col xs="auto" className="d-flex align-items-end">
                <Button onClick={() => navigate(`/classroom/` + c.slug)}>
                  Start Course
                  <FontAwesomeIcon className="ms-2" icon={faArrowCircleRight} />
                </Button>
              </Col>
            </Row>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }
  return <ul style={{ padding: "0px", margin: "0px" }}>{lstCourses}</ul>
}
